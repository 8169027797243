import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

export const AdvancedMarketTopAwardedCagesTable = ({ rows, style }) => {

    const topAwardedCagesColumns = [
        {
            name: 'Cage Code',
            selector: row => row.cage_code,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => alert("This feature is coming soon...")}>{row.cage_code}</a>
            </>
        },
        {
            name: 'Name',
            selector: row => row.company_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.company_name}>{row.company_name.substring(0,15) + ' ...'}</a>
            </>
        },
        {
            name: 'Awards',
            selector: row => row.award_count,
        },
        {
            name: 'Award Value',
            selector: row => CurrencyFormatter.format(row.total_value),
        },
    ]

    return (
        <>
            <DataTable
                columns={topAwardedCagesColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}