import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";

import { Sidebar } from './Sidebar';
import { Header } from './Header';

export const LayoutPresent = () => {
    // Theme change dark to light
    const [theme, setTheme] = useState('dark');

    useEffect(() => {
        // Update the body class when the theme changes
        document.body.className = theme;
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    return (
        <>
            <section id="page-container"
                className="row flex-nowrap m-0 p-0"
                style={{
                    height: '100%',
                    marginRight: '20px',
                    color: 'var(--font-color)',
                    backgroundImage: 'var(--background-gradient)',
                }}>

                <Sidebar toggleTheme={toggleTheme} />

                <article id="dashboard"
                    className="col d-flex justify-content-center m-0 p-0">

                    <div className="m-0 p-0" style={{ width: '99%', maxWidth: '1600px' }}>
                        <Header theme={theme} />

                        <Outlet theme={theme} />
                    </div>
                </article>

            </section>
        </>
    );
}
