import React, { useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

export const GenericLine = ({ labels, datasets, title = "", theme }) => { // Accept theme as a prop
    const chartRef = useRef(null);

    // Define theme-dependent colors
    const themeColors = {
        light: {
            borderColor: 'black',
            pointBackgroundColor: 'black',
            gridColor: 'rgba(0,0,0,0.1)',
            tickColor: 'black',
            legendColor: 'black',
        },
        dark: {
            borderColor: 'white',
            pointBackgroundColor: 'white',
            gridColor: 'rgba(255,255,255,0.1)',
            tickColor: 'white',
            legendColor: 'white',
        },
    };

    // Select colors based on the current theme
    const colors = themeColors[theme];

    useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            const ctx = chart.ctx;

            chart.data.datasets.forEach((dataset, i) => {
                const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0); // Gradient from top to bottom
                gradient.addColorStop(0, dataset.gradient.start); // lighter orange
                gradient.addColorStop(1, dataset.gradient.stop); // darker orange-red

                dataset.backgroundColor = gradient;
                dataset.borderColor = 'transparent'; // Set the border color to transparent
            });
            chart.update();
        }
    }, [theme]); // Add theme to the dependency array to react to changes

    const data = {
        labels: labels,
        datasets: datasets
    }

    const options = {
        scales: {
            x: {
                grid: {
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
            },
            y: {
                grid: {
                    color: 'white',
                },
                ticks: {
                    color: 'white',
                },
            },
        },
        
        plugins: {
            legend: {
                labels: {
                    color: 'white',
                    usePointStyle: true,
                    // Generating labels with custom padding-left values
                    generateLabels: (chart) => {
                        const original = Chart.defaults.plugins.legend.labels.generateLabels;
                        const labelsOriginal = original.call(this, chart);
                        return labelsOriginal.map(labelItem => {
                            labelItem.text = `     ${labelItem.text}`; // Add spaces for simulated padding
                            return labelItem;
                        });
                    },
                },
            },
            title: {
                display: false,
                text: title
            }
        },
        maintainAspectRatio: false,
    };

    return (
        <article className="canvas-container">
            <Line ref={chartRef} data={data} options={options} style={{ width: '100%' }} />
        </article>
    );
};
