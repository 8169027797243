import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { PartsApi } from "../api/PartsApi";
import { PageHeader } from '../components/shared/PageHeader';
import { ContextModal } from '../components/shared/ContextModal';
import { Tabs } from '../components/Tabs';

import { MoeTable } from '../components/parts/MoeTable';
import { SolicitationHistoryTable } from '../components/parts/SolicitationHistoryTable';
import { WsdcTable } from '../components/parts/WsdcTable';
import { WsdcCommonNameTable } from '../components/parts/WsdcCommonNameTable';
import { ReplacedNiinsTable } from '../components/parts/ReplacedNiinsTable';
import { ManagementTable } from '../components/parts/ManagementTable';
import { StockOnHandTable } from '../components/parts/StockOnHandTable';

import { PartStockOnHand } from '../components/parts/Charts/PartStockOnHand';
import { PartBackOrderQty } from '../components/parts/Charts/PartBackOrderQty';
import { PartReorderPointQty } from '../components/parts/Charts/PartReorderPointQty';
import { Graph } from '../components/reports/Charts/AreaChartTest';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToCircle, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import BeatLoader from 'react-spinners/BeatLoader';
import GridLoader from 'react-spinners/GridLoader';

import CreatableSelect from 'react-select/creatable';
import { CurrencyFormatter, NumberFormatter } from "../utils/NumberFormatter";

export const PartDetail = ({ theme }) => {
    const navigate = useNavigate();
    const routeChange = (niin) => {
        let path = `view/${niin}`;
        navigate(path);
    }

    const [part, setPart] = React.useState(null);
    const [dataLoading, setDataLoading] = React.useState(true);

    const [procHistRows, setProcHistRows] = React.useState([]);
    const [procHistRowsLoading, setProcHistRowsLoading] = React.useState(false);

    const [wsdcDetailRows, setWsdcDetailRows] = React.useState([]);
    const [wsdcDetailRowsLoading, setWsdcDetailRowsLoading] = React.useState(false);

    const [moeDetailRows, setMoeDetailRows] = React.useState([]);
    const [moeDetailRowsLoading, setMoeDetailRowsLoading] = React.useState(false);

    const [solicitationHistoryRows, setSolicitationHistoryRows] = React.useState([]);
    const [solicitationHistoryRowsLoading, setSolicitationHistoryRowsLoading] = React.useState(false);

    const [priceReasonRows, setPriceReasonRows] = React.useState([]);
    const [priceReasonRowsLoading, setPriceReasonRowsLoading] = React.useState(false);

    const [stockOnHandRows, setStockOnHandRows] = React.useState([]);
    const [stockOnHandRowsLoading, setStockOnHandRowsLoading] = React.useState(false);

    const [reorderPointHistoryRows, setReorderPointHistoryRows] = React.useState([]);
    const [reorderPointHistoryRowsLoading, setReorderPointHistoryRowsLoading] = React.useState(false);

    const [replacementNiinRows, setReplacementNiinRows] = React.useState([]);
    const [replacementNiinRowsLoading, setReplacementNiinRowsLoading] = React.useState(false);

    const [managementDataRows, setManagementDataRows] = React.useState([]);
    const [managementDataRowsLoading, setManagementDataRowsLoading] = React.useState(false);

    const [stockData, setStockData] = React.useState(null);
    const [stockDataLoading, setStockDataLoading] = React.useState(false);

    const [classificationData, setClassificationData] = React.useState(null);
    const [classificationDataLoading, setClassificationDataLoading] = React.useState(false);

    const [freightData, setFreightData] = React.useState(null);
    const [freightDataLoading, setFreightDataLoading] = React.useState(null);

    const [procurementInsightData, setProcurementInsightData] = React.useState(null);
    const [procurementInsightDataLoading, setProcurementInsightDataLoading] = React.useState(false);

    const [badRequest, setBadRequest] = React.useState(false);
    const { niin } = useParams();

    useEffect(() => {
        Promise.all([
            getPartDetails(),
            getProcurementHistory(),
            getWsdcDetails(),
            getMoeDetails(),
            getProcurementInsights(),
            getSolicitationHistory(),
            getPriceReasonRows(),
            getStockOnHand(),
            getReorderPointHistory(),
            getReplacementNiins(),
            getManagementData(),
            getStockData(),
            getClassificationData(),
            getFreightDimensionData()
        ]).then();
    }, []);

    const getPartDetails = () => {
        return new Promise((resolve, reject) => {
            PartsApi.view(niin).then((response) => {
                setPart(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 1");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setDataLoading(false);
            });
        });
    }

    const getProcurementHistory = () => {
        setProcHistRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchProchist(niin).then((response) => {
                setProcHistRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request not 1");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setProcHistRowsLoading(false);
            });
        });
    }

    const getWsdcDetails = () => {
        setWsdcDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchWsdc(niin).then((response) => {
                setWsdcDetailRows(response.data);
                resolve(response.data);
            })
            .catch((err) => {
                console.log("Bad Request 2");
                setBadRequest(true);
                reject(err);
            })
            .finally(() => {
                setWsdcDetailRowsLoading(false);
            });
        });
    }

    const getMoeDetails = () => {
        setMoeDetailRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchMoe(niin).then((response) => {
                setMoeDetailRows(response.data);
                resolve(response.data);
            })
                .catch((err) => {
                    console.log("Bad Request 3");
                    setBadRequest(true);
                    reject(err);
                })
                .finally(() => {
                    setMoeDetailRowsLoading(false);
                });
        });
    }

    const getProcurementInsights = () => {
        setProcurementInsightDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchProcurementInsights(niin).then((response) => {
                setProcurementInsightData(response.data);
                resolve(response.data);
            })
                .catch((err) => {
                    console.log("Bad Request 13");
                    setBadRequest(true);
                    reject(err);
                })
                .finally(() => {
                    setProcurementInsightDataLoading(false);
                });
        });
    }

    const getSolicitationHistory = () => {
        setSolicitationHistoryRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchSolicitationHistory(niin).then((response) => {
                setSolicitationHistoryRows(response.data);
                resolve(response.data);
            })
                .catch((err) => {
                    console.log("Bad Request 4");
                    setBadRequest(true);
                    reject(err);
                })
                .finally(() => {
                    setSolicitationHistoryRowsLoading(false);
                });
        });
    }

    const getPriceReasonRows = () => {
        setPriceReasonRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchPriceReasons(niin).then((response) => {
                setPriceReasonRows(response.data);
                resolve(response.data);
            })
                .catch((err) => {
                    console.log("Bad Request 12");
                    setBadRequest(true);
                    reject(err);
                })
                .finally(() => {
                    setPriceReasonRowsLoading(false);
                });
        });
    }

    const getStockOnHand = () => {
        setStockOnHandRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchStockOnHand(niin).then((response) => {
                setStockOnHandRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 5");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setStockOnHandRowsLoading(false);
            });
        })
    }

    const getReorderPointHistory = () => {
        setReorderPointHistoryRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchReorderPointHistory(niin).then((response) => {
                setReorderPointHistoryRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 11");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setReorderPointHistoryRowsLoading(false);
            });
        })
    }

    const getReplacementNiins = () => {
        setReplacementNiinRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchReplacements(niin, true).then((response) => {
                setReplacementNiinRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 6");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setReplacementNiinRowsLoading(false);
            });
        })
    }

    const getManagementData = () => {
        setManagementDataRowsLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchManagement(niin).then((response) => {
                setManagementDataRows(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 7");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setManagementDataRowsLoading(false);
            });
        })
    }

    const getStockData = () => {
        setStockDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchStockData(niin).then((response) => {
                setStockData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 8");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setStockDataLoading(false);
            });
        })
    }

    const getClassificationData = () => {
        setClassificationDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchItemClassification(niin).then((response) => {
                setClassificationData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 9");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setClassificationDataLoading(false);
            });
        })
    }

    const getFreightDimensionData = () => {
        setFreightDataLoading(true);
        return new Promise((resolve, reject) => {
            PartsApi.searchFreightAndDimensions(niin).then((response) => {
                setFreightData(response.data);
                resolve(response.data);
            }).catch((err) => {
                console.log("Bad Request 10");
                setBadRequest(true);
                reject(err);
            }).finally(() => {
                setFreightDataLoading(false);
            });
        })
    }

    //const partSourceTabs = [
    //    { key: 'Approved', header: 'Approved' },
    //    { key: 'ProcHist', header: 'Procurement Hist.' },
    //    { key: 'PID', header: 'PID' }
    //]

    //const [currentPartSourceTab, setCurrentPartSourceTab] = React.useState(localStorage.getItem('currentTab') || partSourceTabs[0].key);

    //const handlePartSourceTabChange = (tab) => setCurrentPartSourceTab(tab);

    const procurementSourceColumns = [
        {
            name: 'Cage Code',
            selector: row => row.cage_code,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => alert("Company Detail page coming soon...")}>{row.cage_code}</a>
            </>
        },

        {
            name: 'Part Number(s)',
            selector: row => row.part_numbers ?? "N/A",
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.part_numbers != null && row.part_numbers.length > 0 ? row.part_numbers.toString() : "None to display"}><FontAwesomeIcon icon={faMagnifyingGlass} /></a>
            </>
        },

        {
            name: 'Date',
            selector: row => row.date,
            sortable: true
        },

        {
            name: 'Market Capture',
            selector: row => '% ' + (row.win_percentage * 100).toFixed(2).toString(),
            sortable: true
        }
    ];

    const approvedSourceColumns = [
        {
            name: 'Cage Code',
            selector: row => row.cage_code,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => alert("Company Detail page coming soon...")}>{row.cage_code}</a>
            </>
        },

        {
            name: 'Part Number',
            selector: row => row.part_number ?? "N/A",
        },

        {
            name: 'RNCC/RNVC',
            selector: row => (row.rncc ? row.rncc : 'N/A') + ',  ' + (row.rnvc ? row.rnvc : 'N/A'),
            sortable: true
        },

        {
            name: 'DAC',
            selector: row => row.dac,
        },

        {
            name: 'RNAAC',
            selector: row => row.rnaac,
        },

        {
            name: 'RNJC',
            selector: row => row.rnjc,
        },

        {
            name: 'RNFC',
            selector: row => row.rnfc,
        },

        {
            name: 'RNSC',
            selector: row => row.rnsc,
        },

        //{
        //    name: 'SADC',
        //    selector: row => row.sadc,
        //},

        //{
        //    name: 'hcc',
        //    selector: row => row.hcc,
        //},

        //{
        //    name: 'MSDS',
        //    selector: row => row.msds,
        //},
    ];

    const procurementHistoryColumns = [
        {
            name: 'Contract Number',
            selector: row => row.contract_number ?? "N/A",
        },
        {
            name: 'Reference Number',
            selector: row => row.referenced_piid
        },
        {
            name: 'Awardee',
            selector: row => row.cage ?? "N/A",
        },
        {
            name: 'Date',
            selector: row => row.award_date ?? "N/A",
            sortable: true
        },
        {
            name: 'Total Price',
            selector: row => (row.total_price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Qty',
            selector: row => (row.order_qty ?? "N/A") + " " + (row.unit ?? "N/A"),
        },
        {
            name: 'Unit Price',
            selector: row => (row.netprice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Price Reason',
            selector: row => row.price_reason
        },
        {
            name: 'Solicitation',
            selector: row => row.solic_amendment_number
        }
    ]

    const procurementHistoryPropColumns = [
        {
            name: 'Contract Number',
            selector: row => row.contract_number ?? "N/A",
        },
        {
            name: 'Delivery Code',
            selector: row => row.delivery_code
        },
        {
            name: 'Awardee',
            selector: row => row.cage ?? "N/A",
        },
        {
            name: 'Award Date',
            selector: row => row.award_date ?? "N/A",
            sortable: true
        },
        {
            name: 'Total Price',
            selector: row => (row.total_price?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Qty',
            selector: row => row.order_qty ?? "N/A",
        },
        {
            name: 'Unit Price',
            selector: row => (row.unit_price?.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Delivery Date',
            selector: row => row.delivery_date
        },
        {
            name: 'Total Bids',
            selector: row => row.total_bids
        }
    ]

    const priceReasonColumns = [
        {
            name: 'Contract Number',
            selector: row => row.contract_number ?? "N/A",
        },

        {
            name: 'Awardee',
            selector: row => row.cage_code ?? "N/A",
        },
        {
            name: 'Date',
            selector: row => row.award_date ?? "N/A",
            sortable: true
        },
        {
            name: 'Total Price',
            selector: row => (row.total_price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Qty',
            selector: row => (row.order_qty ?? "N/A") + " " + (row.unit ?? "N/A"),
        },
        {
            name: 'Unit Price',
            selector: row => (row.netprice.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            }) ?? "N/A"),
        },
        {
            name: 'Price Reason',
            selector: row => row.price_type
        },
        {
            name: 'Reviewer',
            selector: row => row.price_rvwr
        }
    ]

    const customStylesDark = {
        table: {
            style: {
                fontSize: '12px'
            }
        },
        header: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        headRow: {

            style: {
                padding: "6px 8px 6px 8px",
                backgroundColor: '#1D2839',
                color: 'white',
            },
        },
        rows: {
            style: {
                padding: "6px 8px 6px 8px",
                '&:nth-of-type(even)': {
                    color: 'white',
                    backgroundColor: '#1D2839'
                },
                '&:nth-of-type(odd)': {
                    color: 'white',
                    backgroundColor: '#39445C'
                },
            },
        },
        pagination: {
            style: {
                backgroundColor: '#1D2839',
                color: 'white',
                borderTop: '1px solid white'
            }
        }
    };

    const customStylesLight = {

        header: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        headRow: {
            style: {
                backgroundColor: 'white',
                color: 'black',
            },
        },
        rows: {
            style: {
                '&:nth-of-type(even)': {
                    color: 'black',
                    backgroundColor: '#e8e8e8'
                },
                '&:nth-of-type(odd)': {
                    color: 'black',
                    backgroundColor: 'white'
                },
            },
        }
    };

    const renderBody = () => {
        return (
            <>
                <PageHeader pageName={"Part Detail"} />

                <div className="row m-0 mt-4 mb-4 p-0 justify-content-center">
                    <div className="m-0 p-0 col-11 mb-4 card-element" id="graph-bounding-box">
                        <div className="card">

                            <div className="card-header p-0">
                                <div className="row">
                                    <div className="col-4">
                                        <h5 className="mb-0">
                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                {part.fsc + '-' +  part.niin.substring(0,2) + '-' + part.niin.substring(2,5) + '-' + part.niin.substring(5) + ',    ' + part.nomenclature}
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="col-4">
                                        <h5 className="mb-0">
                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                {'Assigned: ' + new Date(part.niin_asgmt)?.toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }) ?? "N/A"}
                                            </span>
                                        </h5>
                                    </div>
                                    <div className="col-3 offset-md-1 text-right">
                                        <h5 className="mb-0">
                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                {
                                                    part.replacement_niin != null ?
                                                        <>
                                                            {
                                                                'Replacement: '
                                                            }
                                                            <a className="ml-1" style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => routeChange(part.replacement_niin)}>{part.replacement_niin}</a>
                                                        </>
                                                        : 'No Replacement'
                                                }
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body" style={{ backgroundColor: '#1D2839' }}>
                                <div className="row">
                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>Classification:</b></p>
                                            <div className="ml-1">{part.classification}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>Item Name Code:</b></p>
                                            <div className="ml-1">{part.inc}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>Budget Code:</b></p>
                                            <div className="ml-1">{part.budget}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>ERRC:</b></p>
                                            <div className="ml-1">{part.errc}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>FIIG:</b></p>
                                            <div className="ml-1">{part.fiig}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>Criticality:</b></p>
                                            <div className="ml-1">{part.criticality}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>Demil. and Integrity:</b></p>
                                            <div className="ml-1">{part.dmil + ' , ' + part.dmil_int_cd}</div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <div className="row">
                                            <p><b>NATO FMS Number:</b></p>
                                            <div className="ml-1">{part.nato_fmsn}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12">
                        
                        <div className="row">

                            <div className="col-xl-7 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Approved Sources</span>
                                                </h5>
                                            </div>
                                        </div>
                                        {/*<Tabs tabSet={partSourceTabs} currentTab={currentPartSourceTab} onTabSelect={handlePartSourceTabChange} />*/}
                                        <DataTable
                                            columns={approvedSourceColumns}
                                            data={part.approved_sources}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-5 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Procurement/PID Sources</span>
                                                </h5>
                                            </div>
                                        </div>
                                        {/*<Tabs tabSet={partSourceTabs} currentTab={currentPartSourceTab} onTabSelect={handlePartSourceTabChange} />*/}
                                        <DataTable
                                            columns={procurementSourceColumns}
                                            data={part.procurement_sources}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-12 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>MOE Rules</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !moeDetailRowsLoading ?
                                                <MoeTable rows={moeDetailRows} style={customStylesDark} />
                                                : <div className="row m-0 p-4" style={{
                                                    textAlign: 'center'
                                                }}>
                                                    <div className="col">
                                                        <BeatLoader color={"#BF663F"} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-xl-12 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Procurement History</span>
                                                </h5>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={procurementHistoryColumns}
                                            data={part.proc_hist}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-xl-12 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-6">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Procurement History</span>
                                                        </h5>
                                                            
                                                    </div>
                                                    <div className="col-1">
                                                        <h5><span class="mt-2 badge badge-info">Proprietary</span></h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={procurementHistoryPropColumns}
                                            data={procHistRows}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-xl-9 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Price Reasonableness</span>
                                                </h5>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={priceReasonColumns}
                                            data={priceReasonRows}
                                            customStyles={customStylesDark}
                                            pagination
                                            dense
                                        />

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Procurement Insights</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !procurementInsightDataLoading ?
                                                        procurementInsightData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <p><b>Percent Solicitations Awarded:</b></p>
                                                                                <div className="ml-1">{'% ' + procurementInsightData.solic_to_award_percentage}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-12">
                                                                            <div className="row">
                                                                                <p><b>Avg Days to Award:</b></p>
                                                                                <div className="ml-1">{procurementInsightData.solic_to_award_avg_days + ' days'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Classification Info</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !classificationDataLoading ?
                                                        classificationData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Classification:</b></p>
                                                                                <div className="ml-1">{classificationData.classification_unified_cages}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Classification Date:</b></p>
                                                                                <div className="ml-1">{classificationData.unified_cages_date}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>MCRL Classification:</b></p>
                                                                                <div className="ml-1">{classificationData.mcrl_unified_classification}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>MCRL Class. Date:</b></p>
                                                                                <div className="ml-1">{classificationData.mcrl_unified_date}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>QPL Controlled:</b></p>
                                                                                {
                                                                                    classificationData.qpl_controlled ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Source Controlled:</b></p>
                                                                                {
                                                                                    classificationData.source_controlled ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Design Controlled:</b></p>
                                                                                {
                                                                                    classificationData.design_controlled ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Data Rights:</b></p>
                                                                                {
                                                                                    classificationData.data_rights ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Open Tech Data:</b></p>
                                                                                {
                                                                                    classificationData.open_tech_data ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Unavailable Data:</b></p>
                                                                                {
                                                                                    classificationData.unavailable_data ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>

                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Freight and Dimensions</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !freightDataLoading ?
                                                        freightData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>DSS Weight:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.dss_weight) + ' pounds'}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>DSS Cube:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.dss_cube) + ' cubic feet'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Unit Pack Weight:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.unit_pack_weight) + ' pounds'}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Unit Pack Cube:</b></p>
                                                                                <div className="ml-1">{NumberFormatter.format(freightData.unit_pack_cube) + ' cubic feet'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>NMFC:</b></p>
                                                                                <div className="ml-1">{freightData.nmfc + ' - ' + freightData.nmfc_sub}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>NMF Desc.:</b></p>
                                                                                <div className="ml-1">{freightData.nmf_desc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Activity Code:</b></p>
                                                                                <div className="ml-1">{freightData.acty_cd ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>HazMat Code:</b></p>
                                                                                <div className="ml-1">{freightData.hmc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Special Handling Code:</b></p>
                                                                                <div className="ml-1">{freightData.shc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Uniform Freight Class. Code:</b></p>
                                                                                <div className="ml-1">{freightData.ufc ?? "N/A"}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>

                                                }

                                            </div>



                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">

                        <div className="row">
                            <div className="col-xl-10 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Stock On Hand for Past 12 Months</span>
                                                </h5>
                                            </div>
                                        </div>

                                        <StockOnHandTable data={stockOnHandRows} style={customStylesDark}/>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0" style={{ textAlign: 'center' }}>
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Re-Order Qty Point</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                            <div className="row m-0 p-4" style={{
                                                textAlign: 'center'
                                            }}>
                                                <div className="col">
                                                    {
                                                        !stockDataLoading ?
                                                            stockData != null ?
                                                                <>
                                                                    <b>{stockData.re_order_qty_point}</b>
                                                                </>
                                                                : <>No Data Loaded.</>
                                                            : <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-4 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-6">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Stock On Hand</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !stockOnHandRowsLoading ?
                                                        <PartStockOnHand history={stockOnHandRows} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-6">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Back Order Qty</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !stockOnHandRowsLoading ?
                                                        <PartBackOrderQty history={stockOnHandRows} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-6">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Re-Order Qty Points</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !reorderPointHistoryRowsLoading ?
                                                        <PartReorderPointQty history={reorderPointHistoryRows} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Financials</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !stockDataLoading ?
                                                        stockData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Market Price:</b></p>
                                                                                <div className="ml-1">{CurrencyFormatter.format(stockData.market_price)}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Annual Consumption:</b></p>
                                                                                <div className="ml-1">{CurrencyFormatter.format(stockData.annual_consumption)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Gov. Estimated 12Mo. Purchase Value:</b></p>
                                                                                <div className="ml-1">{CurrencyFormatter.format(stockData.est12_mo_purchase_revenue)}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Gov. Estimated 24Mo. Purchase Value:</b></p>
                                                                                <div className="ml-1">{CurrencyFormatter.format(stockData.est24_mo_purchase_revenue)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Avg. Annual 24Mo. Revenue:</b></p>
                                                                                <div className="ml-1">{CurrencyFormatter.format(stockData.avg_annual24_month_revenue)}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Avg. Annual 60Mo. Revenue:</b></p>
                                                                                <div className="ml-1">{CurrencyFormatter.format(stockData.avg_annual60_month_revenue)}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>

                                                }

                                            </div>



                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Stock Details</span>
                                                </h5>
                                            </div>

                                            <div className="card-body p-0" style={{ backgroundColor: '#1D2839' }}>
                                                {
                                                    !stockDataLoading ?
                                                        stockData != null ?
                                                            <>
                                                                <div className="container m-3">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Current Annual Consumption:</b></p>
                                                                                <div className="ml-1">{stockData.current_annual_consumption}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Current Reported Stock:</b></p>
                                                                                <div className="ml-1">{stockData.current_stock_on_hand}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Current Stock Out:</b></p>
                                                                                {
                                                                                    stockData.current_stock_out ?
                                                                                        <div className="ml-1"><bold>Y</bold></div>
                                                                                        : <div className="ml-1"><bold>N</bold></div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Current Stock Date:</b></p>
                                                                                <div className="ml-1">{stockData.current_stock_date}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Administrative Lead Time:</b></p>
                                                                                <div className="ml-1">{stockData.alt.toString() + ' days'}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Production Lead Time:</b></p>
                                                                                <div className="ml-1">{stockData.plt.toString() + ' days'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Award Count:</b></p>
                                                                                <div className="ml-1">{stockData.award_count.toString()}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Last Award Date:</b></p>
                                                                                <div className="ml-1">{stockData.last_award_date ?? 'N/A'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Open Order Quantity:</b></p>
                                                                                <div className="ml-1">{stockData.est_open_order_qty.toString()}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Back Order Quantity:</b></p>
                                                                                <div className="ml-1">{stockData.back_order_qtys}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Est. Current Stock:</b></p>
                                                                                <div className="ml-1">{stockData.est_current_stock}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Est. Stock @ Delivery:</b></p>
                                                                                <div className="ml-1">{stockData.est_stock_at_delivery}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Est. Stock After Delivery:</b></p>
                                                                                <div className="ml-1">{stockData.est_stock_after_delivery}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <p><b>Re-Order Quantity Point:</b></p>
                                                                                <div className="ml-1">{stockData.re_order_qty_point}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            : <div className="row m-0 p-4" style={{
                                                                textAlign: 'center'
                                                            }}>
                                                                <div className="col">
                                                                    No Data Loaded.
                                                                </div>
                                                            </div>
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Management</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !managementDataRowsLoading ?
                                                <ManagementTable rows={managementDataRows} style={customStylesDark} />
                                                : <div className="row m-0 p-4" style={{
                                                    textAlign: 'center'
                                                }}>
                                                    <div className="col">
                                                        <BeatLoader color={"#BF663F"} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white'}}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <div className="row justify-content-between">
                                                    <div className="col-3">
                                                        <h5 className="mb-0">
                                                            <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Weapon Systems</span>
                                                        </h5>
                                                    </div>
                                                    <div className="col-3">
                                                        <ul class="nav nav-pills mb-3 float-right" id="pills-tab" role="tablist">
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link active" id="pills-wscn-tab" data-toggle="pill" data-target="#pills-wscn" type="button" role="tab" aria-controls="pills-wscn" aria-selected="true">Common Names</button>
                                                            </li>
                                                            <li class="nav-item" role="presentation">
                                                                <button class="nav-link" id="pills-wsfull-tab" data-toggle="pill" data-target="#pills-wsfull" type="button" role="tab" aria-controls="pills-wsfull" aria-selected="false">Full</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-content" id="pills-tabContent">
                                            <div class="tab-pane fade show active" id="pills-wscn" role="tabpanel" aria-labelledby="pills-wscn-tab">
                                                {
                                                    !wsdcDetailRowsLoading ?
                                                        <WsdcCommonNameTable rows={wsdcDetailRows} style={customStylesDark} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div class="tab-pane fade" id="pills-wsfull" role="tabpanel" aria-labelledby="pills-wsfull-tab">
                                                {
                                                    !wsdcDetailRowsLoading ?
                                                        <WsdcTable rows={wsdcDetailRows} style={customStylesDark} />
                                                        : <div className="row m-0 p-4" style={{
                                                            textAlign: 'center'
                                                        }}>
                                                            <div className="col">
                                                                <BeatLoader color={"#BF663F"} />
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-xl-12 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Solicitation History</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !solicitationHistoryRowsLoading ?
                                                <SolicitationHistoryTable rows={solicitationHistoryRows} style={customStylesDark} />
                                                : <div className="row m-0 p-4" style={{
                                                    textAlign: 'center'
                                                }}>
                                                    <div className="col">
                                                        <BeatLoader color={"#BF663F"} />
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">

                            <div className="col-xl-6 m-0 p-0 pr-1 mt-4">
                                <div className="card-element m-0 p-1">
                                    <div id="accordion" style={{ borderBottom: '1px solid white' }}>
                                        <div className="card" style={{ border: '0' }}>

                                            <div className="card-header p-0">
                                                <h5 className="mb-0">
                                                    <span className="px-2" style={{ display: 'inline-block', marginRight: '15px', paddingTop: '10px', paddingBottom: '10px' }}>Replaced NIIN's</span>
                                                </h5>
                                            </div>
                                        </div>

                                        {
                                            !replacementNiinRowsLoading && replacementNiinRows.length > 0 ?
                                                <ReplacedNiinsTable rows={replacementNiinRows} style={customStylesDark} />
                                                : replacementNiinRowsLoading ?
                                                    <div className="row m-0 p-4" style={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <div className="col">
                                                            <BeatLoader color={"#BF663F"} />
                                                        </div>
                                                    </div>
                                                    : <div className="row m-0 p-4" style={{
                                                        textAlign: 'center'
                                                    }}>
                                                        <div className="col">
                                                            No records to display.
                                                        </div>
                                                    </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                </div>
            </>
        )
    };

    return (
        <>
            <div>
                {
                    dataLoading
                        ? (
                            <div className="progress-container">
                                <div className="progress-bar"></div>
                            </div>
                        )
                        : renderBody()
                }
                {/*<ContextModal />*/}
                {/*<button className="fixed-button">*/}
                {/*    <FontAwesomeIcon icon={faArrowsToCircle} size="2xl" />*/}
                {/*</button>*/}
            </div>
        </>
    );
}