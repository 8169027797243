import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

export const ForecastWsdcTable = ({ rows, style }) => {

    const wsdcDetailColumns = [
        {
            name: 'WSDC',
            selector: row => row.wsdc,
        },
        {
            name: 'WSDC Name',
            selector: row => row.wsdc_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.wsdc_name}>{row.wsdc_name.substring(0, 30) + ' ...'}</a>
            </>
        },
        {
            name: 'Common Name',
            selector: row => row.wsdc_common_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.wsdc_common_name}>{row.wsdc_common_name.substring(0, 30)}</a>
            </>
        },
        {
            name: 'Est. 12Mo Demand Value',
            selector: row => CurrencyFormatter.format(row.gov_est12_mo_purchase_value)
        },
        {
            name: 'Est. 24Mo Demand Value',
            selector: row => CurrencyFormatter.format(row.gov_est24_mo_purchase_value)
        }
    ]

    return (
        <>
            <DataTable
                columns={wsdcDetailColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}