import axios from 'axios'
import { addInterceptor } from "./addInterceptor";

const apiBase = axios.create({
    baseURL: `/api/v1/companies`
})

addInterceptor(apiBase)

export const CompaniesApi = {

    cageView: (cage) => {
        return apiBase.get(`view/cage/${cage}`);
    }
};