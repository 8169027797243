import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

export const MoeTable = ({ rows, style }) => {

    const moeColumns = [
        {
            name: 'Moe Rule',
            selector: row => row.moe_rule,
        },
        {
            name: 'Moe Code',
            selector: row => row.moe_code,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.moe_description}>{row.moe_code}</a>
            </>
        },
        {
            name: 'PICA',
            selector: row => row.pica,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.pica_description}>{row.pica}</a>
            </>
        },
        {
            name: 'SICA',
            selector: row => row.sica,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.sica_description}>{row.sica}</a>
            </>
        },
        {
            name: 'Amc',
            selector: row => row.amc,
        },
        {
            name: 'Amsc',
            selector: row => row.amsc,
        },
        {
            name: 'Nimsc',
            selector: row => row.nimsc,
        },
        {
            name: 'Imc',
            selector: row => row.imc,
        },
        {
            name: 'Imca',
            selector: row => row.imca,
        },
        {
            name: 'Aac',
            selector: row => row.aac,
        },
        {
            name: 'Submitter',
            selector: row => row.submtr,
        },
        {
            name: 'Dsor',
            selector: row => row.dsor,
        },
        {
            name: 'Fmr. Moe Rule',
            selector: row => row.fmr_moe_rule,
        },
        {
            name: 'Date Assigned',
            selector: row => row.date_assigned,
        }
    ]

    return (
        <>
            <DataTable
                columns={moeColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}