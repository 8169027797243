import React from 'react';
import { GenericBar } from "./generic/GenericBar";

export const ForecastDemand = ({ forecast = [], chartType = "" }) => {

    const [timeInterval, setTimeInterval] = React.useState("Yearly");
    //const calculatedLabels = React.useMemo(() => createLabels(history), []);
    //const calculatedDatasets = React.useMemo(() => createDatasets(history), []);

    const createLabels = () => {
        let labels = [
            "12 Month",
            "24 Month",
            "36 Month",
            "48 month"
        ];

        return labels;
    }

    //const defaultLabels = () => {
    //    let currentDate = new Date();

    //    return [
    //        (currentDate.getFullYear() - 4).ToString(),
    //        (currentDate.getFullYear() - 3).ToString(),
    //        (currentDate.getFullYear() - 2).ToString(),
    //        (currentDate.getFullYear() - 1).ToString(),
    //        currentDate.getFullYear().ToString()
    //    ]
    //}

    const createDatasets = (forecast) => {
        return [{
            label: "Dollars ($)",
            data: aggregateForecast(forecast),
            fill: true,
            pointBackgroundColor: 'white',
            pointBorderColor: 'transparent',
            gradient: {
                start: 'rgb(244, 168, 68, 0.75)',
                stop: 'rgb(191, 102, 63, 0.75)'
            }
        }];
    }

    const aggregateForecast = (forecast) => {
        let result = [
            Math.round(forecast.reduce((amt, value) => {
                return amt + (value.gov_est12_mo_purchase_qty*value.market_price);
            }, 0)),

            Math.round(forecast.reduce((amt, value) => {
                return amt + (value.gov_est24_mo_purchase_qty * value.market_price);
            }, 0)),

            Math.round(forecast.reduce((amt, value) => {
                return amt + (value.gov_est36_mo_purchase_qty * value.market_price);
            }, 0)),

            Math.round(forecast.reduce((amt, value) => {
                return amt + (value.gov_est48_mo_purchase_qty * value.market_price);
            }, 0)),
        ]

        return result;
    }

    return (

        <div>
            {
                <GenericBar labels={createLabels()} datasets={createDatasets(forecast)} title="Demand Forecasting" />
            }
            {/* Chart.JS Code */}


            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </div>
    );
};
