import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePollVertical, faLeftRight, faMagnifyingGlass, faTools, faBookBookmark, faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Auth } from "aws-amplify";
import userStore from "../../store/userStore";
var md5 = require('md5');

export const Sidebar = ({ toggleTheme }) => {

    const [isExpanded, setIsExpanded] = useState(true);

    const displayName = userStore(state => state.displayName);
    const clearUser = userStore(state => state.clearUser)
    const navigate = useNavigate()
    const userEmail = userStore(state => state.email);
    const [gravatarCode, setGravitarCode] = useState(null);

    useEffect(() => {
        setGravitarCode(md5(userEmail));
    })


    const handleMouseEnter = () => {
        //setIsExpanded(true);
    };

    const handleMouseLeave = () => {
        //setIsExpanded(false);
    };

    const toggleExpanded = (isExpanded) => {
        setIsExpanded(!isExpanded);
    }

    return (
        <>
            <article
                id="sidebar"
                className={`mobile-d-none  col m-0 p-0  flex-column align-items-center ${isExpanded ? 'expanded' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{ height: '100vh', paddingTop: '100px !important', position: 'sticky', top: 0, backgroundColor: 'var(--sidebar-background)' }}
            >
               
                <section className="d-flex flex-column align-items-center" style={{ width: '100%', marginTop: '0px' }}>
                    <div className="row p-0 sidebar-link" style={{ width: '100%', marginTop: '10px', marginBottom: '10px'} }>
                        
                        <a className="nav-link" onClick={() => navigate("/user/profile")} style={{ padding: '0px', color: 'white' }}>
                                <span className="d-inline-flex flex-lg-row align-items-center align-middle">
                                <img src={"https://www.gravatar.com/avatar/" + gravatarCode + "?d=retro&s=30"} className="d-block ui-w-30 rounded-circle mr-2" style={{ marginLeft: '4px' }} />
                                    { isExpanded &&  
                                        <span className="px-1">{displayName}</span>
                                    }
                                </span>
                            </a>
                    </div>
                </section>

                <section className="d-flex flex-column align-items-center" style={{ width: '100%', marginTop: '0px' }}>
                    <div className="row p-0 sidebar-link" style={{ width: '100%', marginTop: '0px' }}>

                        <a className="nav-link" onClick={() => toggleExpanded(isExpanded)} style={{ padding: '0px', width: '100%', textAlign: 'center', color: 'white' }}>
                            <span className="d-inline-flex flex-lg-row align-items-center align-middle">
                                <FontAwesomeIcon icon={faLeftRight} />
                            </span>
                        </a>
                    </div>
                </section>

                <div className="divider" style={{ width: '100%', marginTop: '25px' }}></div>

                <section className="d-flex flex-column align-items-center" style={{ width: '100%', marginTop: '25px' }}>
                    <div className="row p-0 sidebar-link">
                        <FontAwesomeIcon icon={faSquarePollVertical} className="fa-lg" />
                        <button onClick={() => navigate("/reports")} className={`nav-btn ${isExpanded ? '' : 'hidden'}`} style={{ fontSize: '17px'}}>
                            Reports
                        </button>
                    </div>

                    <div className="row p-0 sidebar-link">
                        <FontAwesomeIcon icon={faMagnifyingGlass} className="fa-lg" />
                        <button className={`nav-btn ${isExpanded ? '' : 'hidden'}`} style={{ fontSize: '17px' }}>Explore</button>
                    </div>

                    <div className="row p-0 sidebar-link">
                        <FontAwesomeIcon icon={faBookBookmark} className="fa-lg" />
                        <button className={`nav-btn ${isExpanded ? '' : 'hidden'}`} style={{ fontSize: '17px' }}>Guides</button>
                    </div>

                    <div className="row p-0 sidebar-link">
                        <FontAwesomeIcon icon={faTools} className="fa-lg" />
                        <button onClick={() => navigate("/usg/parts/search")} className={`nav-btn ${isExpanded ? '' : 'hidden'}`} style={{ fontSize: '17px' }}>Parts</button>
                    </div>
                    {/* Divider */}
                    <div className="divider" style={{ width: '100%', marginTop: '83px' }}></div>


                    <div className="row p-0 sidebar-link" onClick={toggleTheme}>
                        <FontAwesomeIcon icon={faSquarePollVertical} className="fa-lg" />
                        <button className={`nav-btn ${isExpanded ? '' : 'hidden'}`} style={{ fontSize: '17px' }}>Theme</button>
                    </div>
                </section>
                <section className="d-flex flex-column align-items-center" style={{ width: '100%' }}>
                    <div className="divider" style={{ width: '100%', marginTop: '83px', marginBottom: '20px' }}></div>
                    <div className="row m-0 mb-5 p-0 sidebar-link">
                        <FontAwesomeIcon icon={faSquarePollVertical} className="fa-lg" />
                        <button className={`nav-btn ${isExpanded ? '' : 'hidden'}`} style={{ fontSize: '17px' }}>Admin</button>
                    </div>
                    <p style={{ opacity: '0.2', color: 'var(--sidebar-btn-text)' }}>v1</p>
                </section>
            </article>

        </>

    );
};

export default Sidebar;