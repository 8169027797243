import axios from 'axios'
import { addInterceptor } from "./addInterceptor";

const apiBase = axios.create({
    baseURL: `/api/v1/reports`
})

addInterceptor(apiBase)

export const ReportsApi = {
    forecast: (queryParams) => {
        return apiBase.get('forecast', { params: queryParams });
    },

    exportForecast: (queryParams) => {
        return apiBase.get('forecast/export', { params: queryParams });
    },

    forecastWsdc: (queryParams) => {
        return apiBase.get('forecast/wsdc', { params: queryParams });
    },

    advancedMarket: (queryParams) => {
        return apiBase.get('advanced_market/header', {
            params: queryParams
        });
    },

    advancedMarketProcHist: (queryParams) => {
        return apiBase.get('advanced_market/prochist', {
            params: queryParams
        });
    },

    exportProcHist: (queryParams) => {
        return apiBase.get('advanced_market/prochist/export', {
            params: queryParams
        });
    },

    advancedMarketNiinDetail: (queryParams) => {
        return apiBase.get('advanced_market/niin_detail', {
            params: queryParams
        });
    },

    advancedMarketTopAwardedCages: (queryParams) => {
        return apiBase.get('advanced_market/cage_detail', {
            params: queryParams
        });
    },

    advancedMarketWSDCDetail: (queryParams) => {
        return apiBase.get('advanced_market/wsdc_detail', {
            params: queryParams
        });
    },

    advancedMarketFSCDetail: (queryParams) => {
        return apiBase.get('advanced_market/fsc_detail', {
            params: queryParams
        });
    },

    advancedMarketTopCagesByApproval: (queryParams) => {
        return apiBase.get('advanced_market/cage_approval_detail', {
            params: queryParams
        });
    }
};