import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

export const WsdcCommonNameTable = ({ rows, style }) => {

    const rowsFormatted = () => {
        return rows.reduce(function (rv, x) {
            let val = rv.find(e => e.common_name == x.common_name);
            console.log("val", JSON.stringify(val))
            if (val === null || val === undefined) {
                rv.push({
                    common_name: x.common_name,
                    count: 1,
                    wsdcs: x.wsdc,
                    top_wsec: x.wsec
                });
            } else {
                val.count += 1;
                val.wsdcs += "," + x.wsdc;
                val.top_wsec = (x.wsec < val.top_wsec) ? x.wsec : val.top_wsec;
            }

            return rv;

        }, []);
    }

    const wsdcColumns = [
        {
            name: 'Common Name',
            selector: row => row.common_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.common_name}>{row.common_name}</a>
            </>
        },
        {
            name: 'Number of WSDCs',
            selector: row => row.count,
        },
        {
            name: 'WSDCs',
            selector: row => row.wsdcs,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.wsdcs}>{row.wsdcs}</a>
            </>
        },
        {
            name: 'Top WSEC',
            selector: row => row.top_wsec,
        },
    ]

    return (
        <>
            <DataTable
                columns={wsdcColumns}
                data={rowsFormatted()}
                customStyles={style}
                pagination
                dense
            />
            
        </>
    );
}