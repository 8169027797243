import React, { useEffect, useState, useMemo } from 'react';
import { GenericBar } from "./generic/GenericBar";

export const MarketShareByControl = ({ history = [] }) => {

    const [timeInterval, setTimeInterval] = React.useState("Yearly");
    const [filter, setFilter] = React.useState("QPL Controlled");
    //const calculatedLabels = React.useMemo(() => createLabels(history), []);
    //const calculatedDatasets = React.useMemo(() => createDatasets(history), []);

    const createLabels = (history) => {
        if (history.length == 0) {
            return [];
        }

        let labels = [];
        if (timeInterval == "Yearly") {
            labels = [...new Set(history.map(item => item.year))].sort();
        }

        else if (timeInterval == "Monthly") {
            labels = [...new Set(history.map(item => item.year + '-' + item.month))].sort();
        }

        return labels;
    }

    //const defaultLabels = () => {
    //    let currentDate = new Date();

    //    return [
    //        (currentDate.getFullYear() - 4).ToString(),
    //        (currentDate.getFullYear() - 3).ToString(),
    //        (currentDate.getFullYear() - 2).ToString(),
    //        (currentDate.getFullYear() - 1).ToString(),
    //        currentDate.getFullYear().ToString()
    //    ]
    //}

    const createDatasets = (history) => {
        return [
            {
                label: 'Wins',
                data: aggregateHistory(history, true),
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(244, 168, 68, 0.75)',
                    stop: 'rgb(191, 102, 63, 0.75)'
                }
            },
            {
                label: 'Losses',
                data: aggregateHistory(history, false),
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(255, 115, 55, 0.75)',
                    stop: 'rgb(163, 38, 38, 0.75)'
                }
            }
        ];
    }

    const aggregateHistory = (history, won = true) => {
        let filtered = [];
        if (filter === "QPL Controlled") {
            filtered = history.filter(item => item.qpl_controlled);
        } else if (filter == "Source Controlled") {
            filtered = history.filter(item => item.source_controlled);
        } else if (filter == "Design Controlled") {
            filtered = history.filter(item => item.design_controlled);
        } else if (filter === "Open Tech Data") {
            filtered = history.filter(item => item.open_tech_data);
        } else if (filter === "Data Rights") {
            filtered = history.filter(item => item.data_rights);
        } else if (filter === "Unavailable Data") {
            filtered = history.filter(item => item.unavailable_data);
        }

        let vDict = filtered.reduce((dic, value) => {
            if (!dic[decideInterval(value)]) {
                dic[decideInterval(value)] = 0;
            }

            if (won) {
                if (value.result == "Won") {
                    dic[decideInterval(value)] += value.total_price;
                }
            }

            else {
                if (value.result == "Lost") {
                    dic[decideInterval(value)] += value.total_price;
                }
            }

            return dic;
        }, {});

        var keys = Object.keys(vDict).sort();

        let result = [];
        keys.forEach(e => {
            result.push(vDict[e]);
        });

        console.log(vDict);
        return result;
    }

    const decideInterval = (data) => {
        if (timeInterval == "Yearly") { return data.year; }
        if (timeInterval == "Monthly") { return data.year + '-' + data.month; }
        return data.year;
    }

    const handleChangeFilter = (event, filter) => {
        event.preventDefault();
        setFilter(filter);
    }

    const classes = [
        "QPL Controlled",
        "Source Controlled",
        "Design Controlled",
        "Data Rights",
        "Open Tech Data",
        "Unavailable Data"
    ]

    return (

        <>
            {
                <div>
                    <div className="row">
                        <div className="col-lg-12 self-align-end text-right">
                            <div class="btn-group">
                                <button type="button" class=" btn chart-dropdown-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    {filter}
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    {
                                        classes.map((cl) => {
                                            return (
                                                <button class="dropdown-item" type="button" onClick={(event) => handleChangeFilter(event, cl)}>{cl}</button>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <GenericBar labels={createLabels(history)} datasets={createDatasets(history)} id="marketShareByControl" />
                </div>
            }
            {/* Chart.JS Code */}


            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </>
    );
};
