import React from 'react'


export const PageHeader = ({ pageName }) => {
    return (
        <>
            <div className="row m-0 p-0 mt-3 mt-md-1 mb-3 mb-md-0 d-flex justify-content-between header" style={{ textTransform: 'uppercase' }}>

                <div className="col-6 col-xl-12 order-1 order-xl-2 m-0 p-0 d-none d-sm-block">
                    <h5 className="pb-0 mt-2 mt-xl-0 mb-xl-0 pl-2" style={{
                        fontSize: '1.5vw',
                        letterSpacing: '3px'
                    }}>{ pageName }</h5>
                </div>
            </div>
        </>
    )
}