import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

export const WsdcTable = ({ rows, style }) => {

    const wsdcColumns = [
        {
            name: 'WSDC',
            selector: row => row.wsdc,
        },
        {
            name: 'Name',
            selector: row => row.wsdc_name,
        },
        {
            name: 'Common Name',
            selector: row => row.common_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.wsdc_name}>{row.common_name}</a>
            </>
        },
        {
            name: 'WSEC',
            selector: row => row.wsec,
        },
        {
            name: 'WSGC',
            selector: row => row.wsgc,
        },
        //{
        //    name: 'WSIC',
        //    selector: row => row.wsic,
        //}
    ]

    return (
        <>
            <DataTable
                columns={wsdcColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}