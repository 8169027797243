import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { IntFormatter } from '../../utils/NumberFormatter';

export const AdvancedMarketTopApprovedCagesTable = ({ rows, style }) => {

    const topApprovedCagesColumns = [
        {
            name: 'Cage Code',
            selector: row => row.cage_code,
            cell: row => <>
                <a style={{ fontWeight: '500', textDecoration: 'underline dotted', 'cursor': 'pointer' }} onClick={() => alert("This feature is coming soon...")}>{row.cage_code}</a>
            </>
        },
        {
            name: 'Name',
            selector: row => row.company_name,
            cell: row => <>
                <a style={{ 'cursor': 'pointer' }} title={row.company_name}>{row.company_name.substring(0, 12) + ' ...'}</a>
            </>
        },
        {
            name: 'Approvals',
            selector: row => row.approval_count,
            format: row => IntFormatter.format(row.approval_count)
        },
    ]

    return (
        <>
            <DataTable
                columns={topApprovedCagesColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}