import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { CurrencyFormatter, PercentageFormatter } from '../../utils/NumberFormatter';

export const ManagementTable = ({ rows, style }) => {

    const columns = [
        {
            name: 'Moe Code',
            selector: row => row.moe,
        },
        {
            name: 'SOS',
            selector: row => row.sos,
        },
        //{
        //    name: 'Name',
        //    selector: row => row.part_name,
        //},
        {
            name: 'AAC',
            selector: row => row.aac,
        },
        {
            name: 'CIIC',
            selector: row => row.ciic,
        },
        {
            name: 'QUP',
            selector: row => row.qup
        },
        {
            name: 'Unit Price',
            selector: row => CurrencyFormatter.format(row.unit_price),
        },
        {
            name: 'Shelf Life',
            selector: row => row.slc
        }
    ]

    return (
        <>
            <DataTable
                columns={columns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}