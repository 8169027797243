import React, { useEffect, useState } from 'react'

import { Navigate, Route, Routes } from 'react-router-dom';

import { LayoutBase } from './components/layout/LayoutBase';
import { LayoutPresent } from './components/layout/LayoutPresent';
import { LayoutMinimal } from './components/layout/LayoutMinimal';

import { PrivateRoutes } from "./router/PrivateRoutes"

import './platform/styles/bootstrap.scss';
// import './platform/styles/theme.scss';
import './platform/styles/analytics.scss';
import './platform/styles/login.scss';

import { Login } from "./pages/Login";

import { UserProfile } from "./pages/user/Profile";
import { ReportsList } from "./pages/reports/List";
import { ReportsForecast } from './pages/reports/Forecast';
import { ReportsAdvancedMarket } from './pages/reports/AdvancedMarket';

import { PartSearch } from './pages/PartSearch';
import { PartDetail } from './pages/PartDetail';

import { Auth, Hub } from 'aws-amplify';
import { amplifyConfig } from "./utils/amplifyConfig";
import { NotFound } from "./pages/NotFound";
import { ListUsers } from "./components/users/ListUsers";
import userStore from "./store/userStore";


Auth.configure(amplifyConfig);

function App() {
    const setUser = userStore(state => state.setUser)
    const [customState, setCustomState] = useState(null);
    const clearUser = userStore(state => state.clearUser);
    const [activeSession, setActiveSession] = useState(false);

    useEffect(() => {
        const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    setUser(data.signInUserSession.idToken.jwtToken, data.signInUserSession.idToken.payload);
                    break;
                case "signOut":
                    setUser(null);
                    clearUser();
                    break;
                case "customOAuthState":
                    setCustomState(data);
            }
        });

        Auth.currentSession().then(res => {
            setActiveSession(true);
        }).catch(() => {
            clearUser();
            setActiveSession(false);
        });

        return unsubscribe;
    }, []);


    return (
        <LayoutBase>
            <>
                <Routes>
                    {activeSession ?
                        <>
                            <Route element={<PrivateRoutes />}>
                                
                                <Route element={<LayoutPresent />}>
                                    <Route path="/" element={<Navigate to="/reports" />} />

                                    <Route element={<UserProfile />} path="/user/profile" />

                                    <Route element={<ListUsers />} path="/users" />
                                    <Route element={<ReportsList />} path="/reports" />
                                    <Route element={<ReportsForecast />} path="/reports/forecast" />
                                    <Route element={<ReportsAdvancedMarket />} path="/reports/advanced_market" />

                                    <Route element={<PartSearch />} path="/usg/parts/search" />
                                    <Route element={<PartDetail />} path="/usg/parts/search/view/:niin" />

                                    <Route element={<NotFound />} path="/not-found" />
                                </Route>
                            </Route>
                        </> : <>
                            <Route element={<LayoutMinimal />}>
                                <Route element={<Login />} path="/" />
                            </Route>
                        </>
                    }
                </Routes>
            </>
        </LayoutBase>
    );
}

export default App;