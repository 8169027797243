import React, { useEffect, useState, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';

import { GenericLine } from "../../reports/Charts/generic/GenericLine";

export const PartStockOnHand = ({ history = [], startDate = "", endDate = "", chartType = "" }) => {

    const [timeInterval, setTimeInterval] = React.useState("Monthly");
    //const calculatedLabels = React.useMemo(() => createLabels(history), []);
    //const calculatedDatasets = React.useMemo(() => createDatasets(history), []);

    const createLabels = (history) => {
        if (history.length == 0) {
            return [];
        }

        let labels = [];
        if (timeInterval == "Yearly") {
            labels = [...new Set(history.map(item => item.year))].sort();
        }

        else if (timeInterval == "Monthly") {
            labels = [...new Set(history.map(item => item.year + '-' + item.month))].sort();
        }

        return labels;
    }

    //const defaultLabels = () => {
    //    let currentDate = new Date();

    //    return [
    //        (currentDate.getFullYear() - 4).ToString(),
    //        (currentDate.getFullYear() - 3).ToString(),
    //        (currentDate.getFullYear() - 2).ToString(),
    //        (currentDate.getFullYear() - 1).ToString(),
    //        currentDate.getFullYear().ToString()
    //    ]
    //}

    const createDatasets = (history) => {
        return [{
            label: 'Quantities',
            data: aggregateHistory(history),
            fill: true,
            pointBackgroundColor: 'white',
            pointBorderColor: 'transparent',
            gradient: {
                start: 'rgb(244, 168, 68, 0.75)',
                stop: 'rgb(191, 102, 63, 0.75)'
            }
        }];
    }

    const aggregateHistory = (history) => {
        let vDict = history.reduce((dic, value) => {
            if (!dic[decideInterval(value)]) {
                dic[decideInterval(value)] = value.total_stock;
            } else {
                dic[decideInterval(value)] += value.total_stock;
            }

            return dic;
        }, {});

        var keys = Object.keys(vDict).sort();

        let result = [];
        keys.forEach(e => {
            result.push(vDict[e]);
        });

        console.log(vDict);
        return result;
    }

    const decideInterval = (data) => {
        if (timeInterval == "Yearly") { return data.year; }
        if (timeInterval == "Monthly") { return data.year + '-' + data.month; }
        return data.year;
    }

    return (

        <>
            <div className="row text-center">
                {/*<div className="col-6">*/}
                {/*    <div className="input-group">*/}
                {/*        <div class="form-check" style={{*/}
                {/*            formCheckInputCheckedColor: "orange"*/}
                {/*        }}>*/}
                {/*            <input class="form-check-input" type="radio" name="chartTypes" id="chartTypeBar" checked />*/}
                {/*            <label class="form-check-label" for="chartTypeBar">*/}
                {/*                Bar Chart*/}
                {/*            </label>*/}
                {/*            <div class="form-check">*/}
                {/*                <input class="form-check-input" type="radio" name="chartTypes" id="chartTypePie" disabled />*/}
                {/*                <label class="form-check-label" for="chartTypePie">*/}
                {/*                    Pie Chart*/}
                {/*                </label>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-4">*/}
                {/*    <div className="input-group">*/}
                {/*        <div class="form-check">*/}
                {/*            <input class="form-check-input" type="radio" name="timeRanges" id="timeRangeYearly" checked />*/}
                {/*            <label class="form-check-label" for="timeRangeYearly">*/}
                {/*                Yearly*/}
                {/*            </label>*/}
                {/*            <div class="form-check">*/}
                {/*                <input class="form-check-input" type="radio" name="timeRanges" id="timeRangeMonthly" disabled />*/}
                {/*                <label class="form-check-label" for="timeRangeMonthly">*/}
                {/*                    Monthly*/}
                {/*                </label>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-11">*/}
                {/*    <div className="row text-center">*/}
                {/*        <h4>Market Size Over Time</h4>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="col-1 text-right">*/}
                {/*    <span title="Export to CSV"><FontAwesomeIcon icon={faFileExport} size="md" /></span>*/}
                {/*</div>*/}
            </div>
            {
                <div>
                    <GenericLine labels={createLabels(history)} datasets={createDatasets(history)} id="stockOnHand" />
                </div>
            }
            {/* Chart.JS Code */}


            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </>
    );
};
