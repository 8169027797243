import React from 'react';
import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

import { NiinRow } from '../shared/NiinRow';

export const ReplacedNiinsTable = ({ rows, style }) => {

    const columns = [
        {
            name: 'Replaced Niin',
            selector: row => row.niin,
            cell: (row, ix) => <>
                <NiinRow ix={ix} niin={row.niin} />
            </>
        },
        {
            name: 'Effective Date',
            selector: row => row.eff_date,
        },
        //{
        //    name: 'Name',
        //    selector: row => row.part_name,
        //},
        {
            name: 'Demil Code',
            selector: row => row.demil,
        },
        {
            name: 'Status Code',
            selector: row => row.niin_stat_code,
        }
    ]

    return (
        <>
            < DataTable
                columns ={ columns }
                data ={ rows}
                customStyles ={ style}
                pagination
                dense
            />
        </>
    );
}