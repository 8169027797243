import React from 'react';

import { useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';

export const SolicitationHistoryTable = ({ rows, style }) => {

    const solicitationHistoryColumns = [
        {
            name: 'Solicitation Number',
            selector: row => row.solicitation_number,
        },
        {
            name: 'Issue Date',
            selector: row => row.issue_date,
        },
        {
            name: 'Close Date',
            selector: row => row.close_date,
        },
        {
            name: 'Pr Number',
            selector: row => row.pr_number,
        },
        {
            name: 'Quantity',
            selector: row => (row.quantity ?? "N/A") + ' ' + (row.uom ?? "N/A"),
        },
        {
            name: 'Tags',
            selector: row => row.tags,
        },
    ]

    return (
        <>
            <DataTable
                columns={solicitationHistoryColumns}
                data={rows}
                customStyles={style}
                pagination
                dense
            />
        </>
    );
}