import { Navigate, Outlet } from "react-router-dom";

export const LayoutMinimal = (props) => {
    return (
        <>
            <div className="layout-wrapper layout-2">
                <div className="layout-inner">

                    <div className="layout-container">
                        <div className="layout-content">
                            {props.children}
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}