import React, { useState } from 'react';

import brand_1 from '../../assets/img/brand-1.svg'
import brand_3 from '../../assets/img/brand-3.svg'

import brand_1_light from '../../assets/img/brand-1-light.svg'
import brand_3_light from '../../assets/img/brand-3-light.svg'


export const Header = ({ theme }) => {

    return (
        <>
            
            <div className="row m-0 p-0 mt-3 mt-md-1 mb-3 mb-md-0 d-flex justify-content-between header" style={{ textTransform: 'uppercase' }}>

                <div className="col-12 col-sm-12 col-xl-12 order-2 order-xl-1 m-0 p-0 d-flex justify-content-end">
                    <div className="row m-0 p-md-0 d-flex justify-content-between align-items-center brand" style={{ width: '100%' }}>

                        {theme === 'dark' ? (
                            <>
                                <img src={brand_1} className="mb-2" height="10" />
                                <h5 style={{ fontWeight: 100 }}>|</h5>
                                <img src={brand_3} className="mt-2" height="30" />
                            </>
                        ) : (
                            <>
                                <img src={brand_1_light} className="mb-2" height="10" />
                                <h5 style={{ fontWeight: 100 }}>|</h5>
                                <img src={brand_3_light} className="mt-2" height="30" />
                            </>
                        )}

                    </div>
                </div>
            </div>

        </>
    );
};
