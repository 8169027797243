import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

export const GenericBar = ({ labels, datasets, title="", stacked = false, id="" }) => {

    // Assuming the ref to the element is obtained correctly
    const chartRef = React.useRef(null);

    const downloadImage = (event) => {
        event.preventDefault();
        const link = document.createElement('a');
        link.download = "chart.jpeg";
        link.href = chartRef.current.toBase64Image('image/jpeg', 1);
        link.click();
    };

    // Define the gradient fill using a React useEffect hook to access the canvas context
    React.useEffect(() => {
        const chart = chartRef.current;

        if (chart) {
            const ctx = chart.ctx;
            // const canvasWidth = chart.width; // Gradient from Left to right
            // const gradient = ctx.createLinearGradient(0, 0, canvasWidth, 0); // Gradient from left to right
            //   --------
            const canvasHeight = chart.height; // Gradient from top to bottom

            // Assign the gradient to the dataset's backgroundColor
            chart.data.datasets.forEach((dataset, i) => {
                const gradient = ctx.createLinearGradient(0, 0, 0, canvasHeight); // Gradient from top to bottom
                gradient.addColorStop(0, dataset.gradient.start); // lighter orange
                gradient.addColorStop(1, dataset.gradient.stop); // darker orange-red

                dataset.backgroundColor = gradient;
                dataset.borderColor = 'transparent'; // Set the border color to transparent
            });
            chart.update();

        }
    }, []);

    // Define the data and options here
    const data = {
        labels: labels,
        datasets: datasets
    };


    const options = {
        scales: {
            x: {
                grid: {
                    color: 'white',
                    lineWidth: 0.5, // Set the x-axis grid line width
                },
                ticks: {
                    color: 'white'
                },
                stacked: stacked
            },
            y: {
                grid: {
                    color: 'white',
                    lineWidth: 0.5, // Set the y-axis grid line width
                },
                ticks: {
                    color: 'white'
                },
                stacked: stacked
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'white'
                }
            },

            title: {
                display: false,
                text: title,
                color: 'white',
                fullSize: true,
                font: {
                    //weight: 'normal',
                    size: 20,
                    //family: 'Helvetica'
                }
            }
        },
        maintainAspectRatio: false,
        responsive: true
    };

    return (
        <>

            {/* Chart.JS Code */}
            <article className="canvas-container">
                <Bar ref={chartRef} data={data} options={options} style={{ width: '100%' }} id={id} />
            </article>
            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </>
    );
};
