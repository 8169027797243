import React from 'react'

import { PageHeader } from '../../components/shared/PageHeader'

export const UserProfile = () => {

    return (
        <>
            <PageHeader pageName={'User Profile'} />


            <div className="row m-0 mt-4 mb-4 p-0 justify-content-center">
                <div className="m-0 p-2 pl-4 pr-4 col-10 card-element" id="graph-bounding-box" style={{ minHeight: '300px' }}>

                    <h4>Feature Flags</h4>

                    <p>
                        List of features that are not in general release/enabled for all users. To get early access to beta features <a href="mailto:ciara.faber@blueravencorp.com">contact</a> BRDS Sedna Analytics product team. 
                    </p>

                    <table className="table table-striped-custom">
                        <thead>
                            <tr>
                                <th style={{ width: '25%' }}>Feature</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                           
                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" checked disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Forecast Reports
                                    </span><br />

                                    Reports that show forecast data for a set of parts alongside DLA consumptio and part classification information. 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Forecast Reports - Algorithm Selection
                                    </span><br />

                                    Feature must be enabled to show algorithm selector in Forecast reports. It not enabled, all reports default to data generated by <code>SALSA 1</code>. 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" checked disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Forecast Algorithms - SALSA 
                                    </span><br />
                                    SALSA (<b>S</b>t<b>a</b>tistica<b>l</b> <b>S</b>tock <b>A</b>lgorthm) is a static model used to generate DLA demand forecasts. It is based on a stocking model for DLA that uses inputs from seven separate sources of data to generate yearly demand forecast values with a five year horizon. 
                                </td>
                            </tr>

                            
                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Forecast Algorithms - SALSA 2H
                                    </span><br />
                                    Algorithm update currently in active development that revises the DLA stocking model based on feedback from SMEs intimately familiar with government purchasing and stocking operations.It utilizes additional parameters, has a feedback loop for error (past corrections against actuals) and produces multidimensional forecasts (time series with value, conviction, cumulative likelihood, etc.)
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Advanced Market Reports
                                    </span><br />

                                    Breakdown of market opportunity for a given cage or set of cages with detailed information on recent performance. 
                                </td>
                            </tr>


                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Forecast Reports - Raw Data - Historic Values
                                    </span><br />

                                    For selected columns with raw data in them, allows user to click and see history of that value. 
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>

                                    <label className="switcher switcher-success">
                                        <input type="checkbox" className="switcher-input" disabled />
                                        <span className="switcher-indicator">
                                            <span className="switcher-yes"></span>
                                            <span className="switcher-no"></span>
                                        </span>
                                    </label>
                                </td>
                                <td>
                                    <span style={{ fontWeight: 'bold' }}>
                                        Forecast Reports - Computed Data - Historic Values
                                    </span><br />

                                    For selected columns with computed values, allows user to click and see historic versions of that computed value. 
                                </td>
                            </tr>


                        </tbody>
                    </table>

                </div>
            </div>


        </>
    )
}