import React from 'react';
import { GenericBar } from "./generic/GenericBar";

export const ForecastDemandByClassification = ({ forecast = [], chartType = "" }) => {

    //const calculatedLabels = React.useMemo(() => createLabels(history), []);
    //const calculatedDatasets = React.useMemo(() => createDatasets(history), []);

    const createLabels = () => {
        let labels = [
            "12 Month",
            "24 Month",
            "36 Month",
            "48 month"
        ];

        return labels;
    }

    //const defaultLabels = () => {
    //    let currentDate = new Date();

    //    return [
    //        (currentDate.getFullYear() - 4).ToString(),
    //        (currentDate.getFullYear() - 3).ToString(),
    //        (currentDate.getFullYear() - 2).ToString(),
    //        (currentDate.getFullYear() - 1).ToString(),
    //        currentDate.getFullYear().ToString()
    //    ]
    //}

    const createDatasets = () => {
        return [
            {
                label: 'Sole Source',
                data: aggregatedForecast.ss,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(244, 168, 68, 0.75)',
                    stop: 'rgb(191, 102, 63, 0.75)'
                }
            },
            {
                label: 'Eff. Sole Source',
                data: aggregatedForecast.ess,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(93,138,168, 0.75)',
                    stop: 'rgb(118,138,157, 0.75)'
                }
            },
            {
                label: 'Dual Source',
                data: aggregatedForecast.ds,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(178,132,190, 0.75)',
                    stop: 'rgb(210,132,179, 0.75)'
                }
            },
            {
                label: 'Competitive',
                data: aggregatedForecast.c,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(175,0,42, 0.75)',
                    stop: 'rgb(110,0,31, 0.75)'
                }
            },
            {
                label: 'Open Tech',
                data: aggregatedForecast.ot,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(176,191,26, 0.75)',
                    stop: 'rgb(145,150,45, 0.75)'
                }
            },
            {
                label: 'Gov Controlled',
                data: aggregatedForecast.gc,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(201,255,229, 0.75)',
                    stop: 'rgb(175,200,218, 0.75)'
                }
            },
            {
                label: 'No Approval',
                data: aggregatedForecast.na,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(128,128,128, 0.75)',
                    stop: 'rgb(128,128,117, 0.75)'
                }
            }
        ];
    }

    const aggregateForecast = (forecast) => {
        let vDict = forecast.reduce((dic, value) => {
            switch (value.classification_unified_cages) {
                case "Sole Source":
                    dic.ss.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.ss.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.ss.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.ss.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                case "Effective Sole Source":
                    dic.ess.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.ess.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.ess.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.ess.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                case "Dual Source":
                    dic.ds.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.ds.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.ds.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.ds.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                case "Competitive":
                    dic.c.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.c.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.c.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.c.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                case "Open Tech Data":
                    dic.ot.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.ot.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.ot.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.ot.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                case "Government Controlled":
                    dic.gc.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.gc.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.gc.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.gc.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                case "No Current Approval":
                    dic.na.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.na.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.na.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.na.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;

                default:
                    dic.na.mo12 += value.gov_est12_mo_purchase_qty * value.market_price;
                    dic.na.mo24 += value.gov_est24_mo_purchase_qty * value.market_price;
                    dic.na.mo36 += value.gov_est36_mo_purchase_qty * value.market_price;
                    dic.na.mo48 += value.gov_est48_mo_purchase_qty * value.market_price;
                    break;
            }

            return dic;

        }, {
            ss: {mo12: 0, mo24: 0, mo36: 0, mo48: 0 },
            ess: { mo12: 0, mo24: 0, mo36: 0, mo48: 0 },
            ds: { mo12: 0, mo24: 0, mo36: 0, mo48: 0 },
            c: { mo12: 0, mo24: 0, mo36: 0, mo48: 0 },
            ot: { mo12: 0, mo24: 0, mo36: 0, mo48: 0 },
            gc: { mo12: 0, mo24: 0, mo36: 0, mo48: 0 },
            na: { mo12: 0, mo24: 0, mo36: 0, mo48: 0 }
        });

        var keys = Object.keys(vDict);

        let result = {};
        keys.forEach(k => {
            result[k] = []

            var keys2 = Object.keys(vDict[k]).sort();
            keys2.forEach(k2 => {
                result[k].push(vDict[k][k2].toFixed(2));
            });
        });

        return result;
    }

    const aggregatedForecast = aggregateForecast(forecast);

    return (

        <div>
            {
                
                <GenericBar labels={createLabels()} datasets={createDatasets()} title="Demand Forecasting By Classification" />
            }
            {/* Chart.JS Code */}


            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </div>
    );
};
