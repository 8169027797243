import React, { useEffect, useState, useMemo } from 'react';
import { GenericBar } from "./generic/GenericBar";

export const MarketShareOverTimePct = ({ history = [] }) => {

    const [timeInterval, setTimeInterval] = React.useState("Yearly");
    //const calculatedLabels = React.useMemo(() => createLabels(history), []);
    //const calculatedDatasets = React.useMemo(() => createDatasets(history), []);

    const createLabels = (history) => {
        if (history.length == 0) {
            return [];
        }

        let labels = [];
        if (timeInterval == "Yearly") {
            labels = [...new Set(history.map(item => item.year))].sort();
        }

        else if (timeInterval == "Monthly") {
            labels = [...new Set(history.map(item => item.year + '-' + item.month))].sort();
        }

        return labels;
    }

    //const defaultLabels = () => {
    //    let currentDate = new Date();

    //    return [
    //        (currentDate.getFullYear() - 4).ToString(),
    //        (currentDate.getFullYear() - 3).ToString(),
    //        (currentDate.getFullYear() - 2).ToString(),
    //        (currentDate.getFullYear() - 1).ToString(),
    //        currentDate.getFullYear().ToString()
    //    ]
    //}

    const createDatasets = (history) => {
        return [
            {
                label: 'Market Capture Pct - Wins',
                data: aggregateHistory(history, true),
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(244, 168, 68, 0.75)',
                    stop: 'rgb(191, 102, 63, 0.75)'
                }
            },
            {
                label: 'Market Capture Pct - Losses',
                data: aggregateHistory(history, false),
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(255, 115, 55, 0.75)',
                    stop: 'rgb(163, 38, 38, 0.75)'
                }
            }
        ];
    }

    const aggregateHistory = (history, won = true) => {
        let vDict = history.reduce((dic, value) => {
            if (!dic[decideInterval(value)]) {
                dic[decideInterval(value)] = {
                    total: 0,
                    sub: 0,
                    pct: 0
                };
            }

            dic[decideInterval(value)].total += value.total_price

            if (won) {
                if (value.result == "Won") {
                    dic[decideInterval(value)].sub += value.total_price;
                }
            }

            else {
                if (value.result == "Lost") {
                    dic[decideInterval(value)].sub += value.total_price;
                }
            }

            dic[decideInterval(value)].pct = (dic[decideInterval(value)].sub / dic[decideInterval(value)].total) * 100;
            return dic;
        }, {});

        var keys = Object.keys(vDict).sort();

        let result = [];
        keys.forEach(e => {
            result.push(Math.round(vDict[e].pct));
        });

        console.log(vDict);
        return result;
    }

    const decideInterval = (data) => {
        if (timeInterval == "Yearly") { return data.year; }
        if (timeInterval == "Monthly") { return data.year + '-' + data.month; }
        return data.year;
    }

    return (

        <>
            {
                <GenericBar labels={createLabels(history)} datasets={createDatasets(history)} stacked={true} title="Market Share Over Time (%)" id="marketShareOverTimePct"/>
            }
            {/* Chart.JS Code */}


            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </>
    );
};
