import React, { useEffect, useState, useMemo } from 'react';
import { GenericBar } from "./generic/GenericBar";

export const ItemClassificationDetailOverTime = ({ history = [], wins = true, losses = true }) => {

    const [timeInterval, setTimeInterval] = React.useState("Yearly");
    //const calculatedLabels = React.useMemo(() => createLabels(history), []);
    //const calculatedDatasets = React.useMemo(() => createDatasets(history), []);

    const createLabels = (history) => {
        if (history.length == 0) {
            return [];
        }

        let labels = [];
        if (timeInterval == "Yearly") {
            labels = [...new Set(history.map(item => item.year))].sort();
        }

        else if (timeInterval == "Monthly") {
            labels = [...new Set(history.map(item => item.year + '-' + item.month))].sort();
        }

        return labels;
    }

    //const defaultLabels = () => {
    //    let currentDate = new Date();

    //    return [
    //        (currentDate.getFullYear() - 4).ToString(),
    //        (currentDate.getFullYear() - 3).ToString(),
    //        (currentDate.getFullYear() - 2).ToString(),
    //        (currentDate.getFullYear() - 1).ToString(),
    //        currentDate.getFullYear().ToString()
    //    ]
    //}

    const createDatasets = (history) => {
        return [
            {
                label: 'Sole Source',
                data: aggregatedHistory.ss,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(244, 168, 68, 0.75)',
                    stop: 'rgb(191, 102, 63, 0.75)'
                }
            },
            {
                label: 'Eff. Sole Source',
                data: aggregatedHistory.ess,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(93,138,168, 0.75)',
                    stop: 'rgb(118,138,157, 0.75)'
                }
            },
            {
                label: 'Dual Source',
                data: aggregatedHistory.ds,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(178,132,190, 0.75)',
                    stop: 'rgb(210,132,179, 0.75)'
                }
            },
            {
                label: 'Competitive',
                data: aggregatedHistory.c,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(175,0,42, 0.75)',
                    stop: 'rgb(110,0,31, 0.75)'
                }
            },
            {
                label: 'Open Tech',
                data: aggregatedHistory.ot,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(176,191,26, 0.75)',
                    stop: 'rgb(145,150,45, 0.75)'
                }
            },
            {
                label: 'Gov Controlled',
                data: aggregatedHistory.gc,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(201,255,229, 0.75)',
                    stop: 'rgb(175,200,218, 0.75)'
                }
            },
            {
                label: 'No Approval',
                data: aggregatedHistory.na,
                fill: true,
                pointBackgroundColor: 'white',
                pointBorderColor: 'transparent',
                gradient: {
                    start: 'rgb(128,128,128, 0.75)',
                    stop: 'rgb(128,128,117, 0.75)'
                }
            },
        ];
    }

    const aggregateHistory = (history) => {
        const decideInterval = (data) => {
            if (timeInterval == "Yearly") { return data.year; }
            if (timeInterval == "Monthly") { return data.year + '-' + data.month; }
            return data.year;
        };

        let vDict = history.reduce((dic, value) => {
            let key = decideInterval(value);
            var keys = Object.keys(dic);
            keys.forEach(k => {
                if (!dic[k].hasOwnProperty(key)) { dic[k][key] = 0; }
            });

            if (!wins && value.result == "Won") {
                return dic;
            }

            if (!losses && value.result == "Lost") {
                return dic;
            }
            
            switch (value.item_classification) {
                case "Sole Source":
                    dic.ss[key] += value.total_price;
                    break;

                case "Effective Sole Source":
                    dic.ess[key] += value.total_price;
                    break;

                case "Dual Source":
                    dic.ds[key] += value.total_price;
                    break;

                case "Competitive":
                    dic.c[key] += value.total_price;
                    break;

                case "Open Tech Data":
                    dic.ot[key] += value.total_price;
                    break;

                case "Government Controlled":
                    dic.gc[key] += value.total_price;
                    break;

                case "No Current Approval":
                    dic.na[key] += value.total_price;
                    break;
            }

            return dic;

        }, {
            ss: {},
            ess: {},
            ds: {},
            c: {},
            ot: {},
            gc: {},
            na: {}
        });

        var keys = Object.keys(vDict);

        let result = {};
        keys.forEach(k => {
            result[k] = []

            var keys2 = Object.keys(vDict[k]).sort();
            keys2.forEach(k2 => {
                result[k].push(vDict[k][k2].toFixed(2));
            });
        });

        return result;
    }

    const aggregatedHistory = aggregateHistory(history);
    console.log(aggregatedHistory);

    return (

        <>
            {
                <GenericBar labels={createLabels(history)} datasets={createDatasets(history)} title="Item Classification Detail Over Time" id="itemClassificationDetailOverTime"/>
            }
            {/* Chart.JS Code */}


            {/* Placeholder hard corded images for Display  */}
            {/* <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100%' }}>
            <img className="d-block d-sm-none" src="./img/graph-1.png" style={{ width: '95%', height: '95%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-sm-block d-md-none" src="./img/graph-2.png" style={{ width: '90%', height: '95%', objectFit: 'contain' }} alt="Graph" />
            <img className="d-none d-md-block d-lg-none" src="./img/graph-3.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-lg-block d-xl-none" src="./img/graph-4.png" style={{ width: '90%', height: '85%', objectFit: 'fit' }} alt="Graph" />
            <img className="d-none d-xl-block" src="./img/graph-6.png" style={{ width: '95%', height: '85%', objectFit: 'fit' }} alt="Graph" />
          </div> */}
        </>
    );
};
